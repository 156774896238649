
import { defineComponent } from 'vue';
import PlanColumnView from '@/components/tables/PlanColumnView.vue';
import store from '@/store';

import { Actions as StoreActions } from '@/store/enums/StoreEnums';
import {
  Getters as SubGetters,
  Actions as SubActions,
} from '@/store/enums/SubscriptionEnums';

import {
  Getters as PlanGetters,
  Actions as PlanActions,
} from '@/store/enums/PlanEnums';
import { mapGetters, mapActions } from 'vuex';
import { Plan } from '@/models/PlanModel';

export default defineComponent({
  name: 'change-plan-page',
  components: {
    PlanColumnView,
  },
  data: () => ({
    planProduct: {} as unknown as Plan,
    loadingPlan: false,
    loadingPlanProduct: false,
  }),
  async mounted() {
    this.fetchCurrentSubscription();

    this.handleFetchPlan();

    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);
  },
  computed: {
    ...mapGetters({
      subscription: SubGetters.GET_SUBSCRIPTION,
      plan: PlanGetters.GET_PLAN,
    }),
  },
  methods: {
    ...mapActions({
      fetchCurrentSubscription: SubActions.FETCH_CURRENT_SUBSCRIPTION,
      fetchPlan: PlanActions.FETCH_PLAN,
      fetchPlanProduct: PlanActions.FETCH_PLAN_PRODUCT,
    }),

    async handleFetchPlan() {
      const params = {
        id: await this.subscription?.plan?.id,
      };

      return await this.fetchPlan(params)
        .then(() => {
          this.loadingPlan = false;
        })
        .catch(() => {
          this.loadingPlan = false;
        })
        .finally(() => {
          this.loadingPlan = false;
        });
    },

    async handleFetchPlanProducts() {
      const params = {
        id: await this.subscription?.items?.data[0]?.price?.product,
      };

      return await this.fetchPlanProduct(params)
        .then(() => {
          this.loadingPlanProduct = false;
        })
        .catch(() => {
          this.loadingPlanProduct = false;
        })
        .finally(() => {
          this.loadingPlanProduct = false;
        });
    },
  },
  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
  },
});
